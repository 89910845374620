import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import EntityListPage from '../Page/EntityListPage'
import SettingsPage from '../Settings/SettingsPage'
import Layout from './Layout'
import ProtectedRoute from './ProtectedRoute'
import PageContainer from '../Page/PageContainer'
import EntityDetailsPage from '../Page/EntityDetailsPage'

const CoreRouter = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute component={Layout} />}>
        <Route path={''} element={<Navigate to={'view'} />} />
        <Route path={'settings'} element={<SettingsPage />} />
        <Route path={'view/*'} element={<PageContainer />}>
          <Route index element={<Navigate to={'account'} replace />} />
          <Route path={':entityName'}>
            <Route index element={<EntityListPage />} />
            <Route path={':entityId/*'} element={<EntityDetailsPage />} />
          </Route>
        </Route>
        <Route path={'login'} />
      </Route>
    </Routes>
  )
}

export default CoreRouter
