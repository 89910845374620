import React, { useMemo, useState } from 'react'
import { EntityTableFilterProps } from '../EntityFilter'
import EntityFilterContainer from '../EntityFilterContainer'
import { Box, TextField } from '@mui/material'
import { produce } from 'immer'
import { EntityTableIntegerFilterState } from '../../EntityTable/types'
import { debounce } from 'lodash'

interface InputState {
  _gte: string
  _lte: string
}

type EntityTableIntegerFilterProps = Omit<EntityTableFilterProps, 'filterState'> & {
  filterState: EntityTableIntegerFilterState
}

const inputStateFromFilterState = (filterState: EntityTableIntegerFilterState) => {
  return {
    _gte: filterState.integer_field._gte?.toString() ?? '',
    _lte: filterState.integer_field._lte?.toString() ?? '',
  }
}

const Integer = (props: EntityTableIntegerFilterProps) => {
  const { filterState, filterDispatch } = props
  const [state, setState] = useState<InputState>(inputStateFromFilterState(filterState))

  function handleInputChange(key: '_gte' | '_lte', nextValue: string) {
    setState((prevState) => {
      if (prevState[key] === nextValue) return prevState
      return {
        ...prevState,
        [key]: nextValue,
      }
    })
  }

  const debouncedDispatch = debounce(() => {
    filterDispatch({
      type: 'INTEGER_FILTER_VALUE_CHANGED',
      filterId: props.filterState.field_id,
      value: produce({}, (draft) => {
        for (const condition of ['_gte', '_lte']) {
          const conditionValue = parseField(state[condition]).parsedInt
          if (conditionValue !== null) {
            draft[condition] = conditionValue
          }
        }
      }),
    })
  }, 500)

  useMemo(debouncedDispatch, [
    debouncedDispatch,
    filterDispatch,
    filterState.field_id,
    state,
  ])

  // useEffect(() => {
  //   debouncedDispatch()
  // }, [filterDispatch, filterState.field_id, state])

  function parseField(value: string) {
    const trimmed = value.trim().replaceAll(',', '')
    if (trimmed === '') {
      return { parsedInt: null, props: { value: '' } }
    }
    const parsedInt = parseInt(trimmed)
    if (Number.isFinite(parsedInt)) {
      if (parsedInt < 0) {
        return {
          parsedInt: null,
          props: {
            value,
            error: true,
            helperText: 'Please enter a non-negative number',
          },
        }
      }
      return {
        parsedInt,
        props: { value: parsedInt.toLocaleString() },
      }
    } else {
      return {
        parsedInt: null,
        props: { value, error: true, helperText: 'Please enter an integer' },
      }
    }
  }

  return (
    <EntityFilterContainer {...props}>
      <Box display={'flex'}>
        <TextField
          value={state._gte}
          size={'small'}
          variant={'outlined'}
          label={'From'}
          sx={{ mr: '0.5rem' }}
          onChange={(nextValue) => handleInputChange('_gte', nextValue.target.value)}
          {...parseField(state._gte).props}
        />
        <TextField
          value={state._lte}
          size={'small'}
          variant={'outlined'}
          label={'To'}
          sx={{ ml: '0.5rem' }}
          onChange={(nextValue) => handleInputChange('_lte', nextValue.target.value)}
          {...parseField(state._lte).props}
        />
      </Box>
    </EntityFilterContainer>
  )
}

Integer.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'EntityTableIntegerFilter',
}

export default Integer
