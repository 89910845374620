import React, { ComponentType } from 'react'
import {
  withAuthenticationRequired,
  WithAuthenticationRequiredOptions,
} from '@auth0/auth0-react'

interface ProtectedRouteProps<P> extends WithAuthenticationRequiredOptions {
  component: ComponentType<P>
}

// From the docs: https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#1-protecting-a-route-in-a-react-router-dom-app
function ProtectedRoute<P>(props: ProtectedRouteProps<P>) {
  const { component, ...args } = props
  const Component = withAuthenticationRequired(component as ComponentType, args)
  return <Component />
}

export default ProtectedRoute
