import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { gql, useQuery } from '@apollo/client'
import { HEADER_HEIGHT } from '../Core/constants'

const GET_USERS = gql`
  query getUsers {
    list_users {
      users {
        user_id
        name
        app_metadata {
          authorization {
            internal_role
          }
        }
      }
    }
  }
`

interface Data {
  list_users: {
    users: {
      user_id: string
      name: string
      app_metadata: {
        authorization: {
          internal_role: string
          customer_id: string
        }
      }
    }[]
  }
}

const SettingsPage = () => {
  const { logout } = useAuth0()
  const { loading, error, data } = useQuery<Data>(GET_USERS)

  return (
    <Box mt={HEADER_HEIGHT} p={'1rem'}>
      <Button
        size={'small'}
        sx={{ alignSelf: 'right', mb: '2rem' }}
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        Logout
      </Button>
      {!loading && !error && (
        <Box>
          <Typography variant={'h5'}>Users</Typography>
          {data.list_users.users.map((user) => (
            <Box>
              {user.user_id} {user.name}{' '}
              {user.app_metadata?.authorization?.internal_role}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default SettingsPage
