import { Box, Paper, Typography } from '@mui/material'
import { get } from 'lodash'
import React, { useState } from 'react'

interface EntityTableFieldListProps {
  title?: string
  rowLimit?: number
  itemKey: string
  items: object[]
}

interface Item {
  name: string
  count?: number
}

const EntityTableFieldList = (props: EntityTableFieldListProps) => {
  const rowLimit = props.rowLimit || 5
  const canShowAllItems = props.items.length <= rowLimit
  const itemsToShow = canShowAllItems ? props.items.length : rowLimit - 1
  const itemsOverLimit = props.items.length - itemsToShow
  const items: Item[] = props.items.map((i) => ({
    name: get(i, props.itemKey) as string,
    count: i['count'],
  }))
  const [showMoreVisible, setShowMoreVisible] = useState(false)
  const hasHidden = itemsOverLimit > 0
  const renderItems = items.map((item, idx) => (
    <Typography key={idx} variant={'body2'} lineHeight={1.3}>
      {item.name} {item.count && 'x ' + item.count}
    </Typography>
  ))
  const visibleItems = renderItems.filter((_, i) => i < itemsToShow)

  return (
    <Box>
      {items.length === 0 && (
        <Typography variant={'body2'} color={'textSecondary'}>
          &mdash;
        </Typography>
      )}

      <Box position={'relative'}>
        {props.title && (
          <Typography
            variant={'overline'}
            color={'textSecondary'}
            sx={{ lineHeight: 1 }}
          >
            {props.title}
          </Typography>
        )}
        <Box>{visibleItems}</Box>
        {showMoreVisible && (
          <Box position={'absolute'} top={-1} left={-1}>
            <Paper
              variant={'outlined'}
              sx={{
                borderColor: 'primary.light',
                position: 'relative',
                top: '-0.25rem',
                left: '-0.5rem',
                zIndex: 4,
                p: 0,
                m: 0,
                py: '0.25rem',
              }}
              onMouseLeave={() => setShowMoreVisible(false)}
            >
              <Box mx={'0.5rem'}>
                {props.title && (
                  <Typography variant={'overline'} color={'textSecondary'}>
                    {props.title}
                  </Typography>
                )}
                {renderItems}
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
      {hasHidden && (
        <Box onMouseEnter={() => setShowMoreVisible(true)}>
          <Typography variant={'body2'} color={'textSecondary'}>
            ...and {itemsOverLimit} more
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default EntityTableFieldList
