import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { CssBaseline } from '@mui/material'
import CoreRouter from './CoreRouter'
import AuthorizedApolloProvider from './AuthorizedApolloProvider'
import { UserTracking } from './UserTracking'

const AUTH0_AUDIENCE = 'hasura'
const GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_ENDPOINT
// console.log("GRAPHQL_URI")
// console.log('window.location.origin', window.location.origin)
// console.log('GRAPHQL_URI', GRAPHQL_URI)
// console.log('REACT_APP_RUN_ID', process.env.REACT_APP_RUN_ID)
// console.log('REACT_APP_AUTH0_CLIENT_ID', process.env.REACT_APP_AUTH0_CLIENT_ID)
// console.log('AUTH0_AUDIENCE', AUTH0_AUDIENCE)

function App() {
  return (
    <>
      <CssBaseline />
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        audience={AUTH0_AUDIENCE}
        redirectUri={window.location.origin}
      >
        <UserTracking />
        <AuthorizedApolloProvider graphqlUri={GRAPHQL_URI}>
          <CoreRouter />
        </AuthorizedApolloProvider>
      </Auth0Provider>
    </>
  )
}

export default App
