import PersonIcon from '@mui/icons-material/Person'
import BusinessIcon from '@mui/icons-material/Business'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import MobilePhoneIcon from '@mui/icons-material/PhoneIphone'
import { grey } from '@mui/material/colors'
import { Badge, Grid, IconButton } from '@mui/material'

const iconSize = '14px'

const copyOnClick = (text) => () => navigator.clipboard.writeText(text)

export const Email = ({ email, type, verification_status }) => {
  const Icon = type === 'work' ? BusinessIcon : PersonIcon

  const badge_color =
    verification_status === 'highly_deliverable' ? 'success' : 'warning'

  return (
    <Grid
      container
      wrap={'nowrap'}
      spacing={1}
      direction={'row'}
      alignItems={'center'}
      onClick={copyOnClick(email)}
      sx={{ cursor: 'pointer' }}
    >
      <Grid item>
        <Badge
          sx={{ color: grey['A400'] }}
          color={badge_color}
          variant={'dot'}
          overlap={'circular'}
        >
          <Icon />
        </Badge>
      </Grid>
      <Grid item>{email}</Grid>
      <Grid item>
        <IconButton size={'small'}>
          <ContentCopyIcon sx={{ color: grey['A400'], fontSize: iconSize }} />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export const Phone = ({ phone, types }) => {
  const formatted_phone = phone.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')

  return (
    <Grid
      container
      wrap={'nowrap'}
      direction={'row'}
      alignItems={'center'}
      onClick={copyOnClick(formatted_phone)}
      sx={{ cursor: 'pointer' }}
    >
      <Grid item>
        <Badge sx={{ color: grey['A400'] }}>
          <MobilePhoneIcon
            sx={{ fontSize: '1.2em' }}
            visibility={types.includes('mobile') ? 'inherit' : 'hidden'}
          />
        </Badge>
      </Grid>
      <Grid item wrap={'nowrap'}>
        {formatted_phone}
      </Grid>
      <Grid item>
        <IconButton size={'small'}>
          <ContentCopyIcon sx={{ color: grey['A400'], fontSize: iconSize }} />
        </IconButton>
      </Grid>
    </Grid>
  )
}
