import React from 'react'
import { useParams } from 'react-router-dom'
import { ENTITY_SETTINGS } from '../../settings/entity-settings'

import { EntityTableWithFilters } from '../EntityTable/EntityTableWithFilters'

const EntityListPage = () => {
  const { entityName } = useParams()

  const entityTableSettings = ENTITY_SETTINGS[entityName].list
  return <EntityTableWithFilters settings={entityTableSettings} key={entityName} />
}

export default EntityListPage
