import { useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router-dom'
import { ENTITY_SETTINGS } from '../../settings/entity-settings'
import PageTitle from '../Core/PageTitle'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Data {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Variables {}

const EntityDetailsPage = () => {
  const { entityName, entityId } = useParams()

  const entitySettings = ENTITY_SETTINGS[entityName]

  const { loading, error, data } = useQuery<Data, Variables>(
    entitySettings.item.query,
    { variables: { entityId } }
  )
  if (loading) return <>Loading...</>
  if (error)
    return (
      <>
        <pre>{JSON.stringify(error.message)}</pre>
      </>
    )

  const entity = data[entityName]

  return (
    <>
      <PageTitle>{entity.name}</PageTitle>
      {entitySettings.item.render(entity)}
    </>
  )
}

export default EntityDetailsPage
