import React, { useEffect, useState, ChangeEvent } from 'react'
import { EntityTableFilterProps } from '../EntityFilter'
import EntityFilterContainer from '../EntityFilterContainer'
import { Box, FormControlLabel, Checkbox } from '@mui/material'
import {
  BooleanFilterOptions,
  EntityTableBooleanFilterState,
} from '../../EntityTable/types'

type EntityTableBooleanFilterProps = Omit<EntityTableFilterProps, 'filterState'> & {
  filterState: EntityTableBooleanFilterState
}
const Boolean = (props: EntityTableBooleanFilterProps) => {
  const { filterDispatch } = props
  const [state, setState] = useState<BooleanFilterOptions>(
    props.filterState.boolean_field || 'any'
  )

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.checked
      ? props.filterState.checked_value
      : props.filterState.unchecked_value
    setState((prevState) => {
      if (prevState === value) return null
      return value as BooleanFilterOptions
    })
  }

  useEffect(() => {
    filterDispatch({
      type: 'BOOLEAN_FILTER_VALUE_CHANGED',
      filterId: props.filterState.field_id,
      value: state as BooleanFilterOptions,
    })
  }, [filterDispatch, props.filterState.field_id, state])

  return (
    <EntityFilterContainer {...props}>
      <Box>
        <FormControlLabel
          name={props.filterState.field_id}
          value={props.filterState.boolean_field}
          control={<Checkbox />}
          label={props.filterState.label}
          onChange={handleInputChange}
          checked={props.filterState.boolean_field === props.filterState.checked_value}
        />
      </Box>
    </EntityFilterContainer>
  )
}

Boolean.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'EntityTableIntegerFilter',
}

export default Boolean
