import { gql, useQuery } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  InputAdornment,
  Paper,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { ListChildComponentProps, VariableSizeList } from 'react-window'
import FilterOption from './FilterOption'
import {
  CategoricalFieldTypeOption,
  EntityTableCategoricalFilterState,
} from '../../EntityTable/types'
import { SearchOutlined as SearchIcon } from '@mui/icons-material'
import { EntityTableFilterProps } from '../EntityFilter'
import EntityFilterContainer from '../EntityFilterContainer'
import CategoricalChipInput from './CategoricalChipInput'

const OPTION_COUNT_THRESHOLD = 6

interface Data {
  options: CategoricalFieldTypeOption[]
}

interface Variables {
  categorical_field_type: string
}

const GET_OPTIONS = gql`
  query categoricalFieldTypeOptions($categorical_field_type: String!) {
    options: categorical_field_option(
      where: { categorical_field_type: { _eq: $categorical_field_type } }
      order_by: { order_key: asc }
    ) {
      id
      long_name
      short_name
      order_key
      categorical_field_type
    }
  }
`

const cleanInputString = (str: string) => str.trim().replaceAll(' ', '').toLowerCase()

export type EntityTableCategoricalFilterProps = Omit<
  EntityTableFilterProps,
  'filterState'
> & {
  filterState: EntityTableCategoricalFilterState
}

const Categorical = (props: EntityTableCategoricalFilterProps) => {
  const { loading, error, data } = useQuery<Data, Variables>(GET_OPTIONS, {
    variables: {
      categorical_field_type:
        props.filterState.categorical_field.categorical_field_type,
    },
  })

  const [open, setOpen] = useState(true)
  const [searchInputValue, setSearchInputValue] = useState('')
  const theme = useTheme()
  const searchInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (open) {
      searchInputRef.current?.focus()
    }
  }, [open, props.filterState.categorical_field.selected_options_map])

  if (loading) {
    return (
      <Box px={'1rem'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant="subtitle1" sx={{ width: '50%' }} gutterBottom>
            <Skeleton />
          </Typography>
        </Box>
        <Skeleton
          variant={'rectangular'}
          sx={{ height: '2.75rem', width: '100%', display: 'block', maxWidth: '100%' }}
        />
      </Box>
    )
  }
  if (error) return <pre>Error: {JSON.stringify(error.message)}</pre>
  if (!data) return <>??</>

  const fieldTypeOptionsFiltered = data.options.filter((option) => {
    const cleanedInput = cleanInputString(searchInputValue)
    if (cleanedInput === '') return true
    return cleanInputString(option.short_name + option.long_name).includes(cleanedInput)
  })

  const handleOptionClick = (optionId: string) => {
    props.filterDispatch({
      type: 'OPTION_TOGGLED',
      filterId: props.filterState.field_id,
      optionId,
    })
    setOpen(true)
  }

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    const option = fieldTypeOptionsFiltered[index]
    const selected =
      option.id in props.filterState.categorical_field.selected_options_map
    return (
      <div key={index} style={style}>
        <FilterOption
          option={option}
          onOptionClick={handleOptionClick}
          selected={selected}
        />
      </div>
    )
  }

  if (data.options.length < OPTION_COUNT_THRESHOLD) {
    // Render as a flat list of checkboxes
    return (
      <EntityFilterContainer onClickAway={() => setOpen(false)} {...props}>
        <VariableSizeList
          width={316}
          height={(theme.typography.fontSize * 3 + 3) * fieldTypeOptionsFiltered.length}
          itemCount={fieldTypeOptionsFiltered.length}
          itemSize={() => theme.typography.fontSize * 3}
        >
          {renderRow}
        </VariableSizeList>
      </EntityFilterContainer>
    )
  } else {
    // Render as an input with ChipInput and a dropdown selection of options
    return (
      <EntityFilterContainer onClickAway={() => setOpen(false)} {...props}>
        <Paper
          variant={'outlined'}
          sx={{
            borderColor: 'grey.400',
            ':hover': {
              borderColor: 'grey.600',
            },
          }}
          onKeyDown={(e) => e.key === 'Escape' && setOpen(false)}
        >
          <CategoricalChipInput
            {...props}
            open={open}
            setOpen={setOpen}
            handleOptionClick={handleOptionClick}
          />
          {open && (
            <Paper
              sx={{
                border: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                position: 'relative',
                m: 0,
                top: -1,
              }}
              variant={'outlined'}
              className={'top-box'}
            >
              <Box px={'1rem'} pb={'0.5rem'}>
                <TextField
                  sx={{
                    '& .MuiInput-root:before, & .MuiInput-root:after': {
                      display: 'none',
                    },
                  }}
                  value={searchInputValue}
                  onChange={(e) => setSearchInputValue(e.target.value)}
                  inputRef={searchInputRef}
                  fullWidth
                  size={'small'}
                  variant={'standard'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <VariableSizeList
                width={'100%'}
                height={300}
                itemCount={fieldTypeOptionsFiltered.length}
                itemSize={() => theme.typography.fontSize * 3}
              >
                {renderRow}
              </VariableSizeList>
            </Paper>
          )}
        </Paper>
      </EntityFilterContainer>
    )
  }
}

export default Categorical
