import { useAuth0 } from '@auth0/auth0-react'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import React, { useState } from 'react'

type AuthorizedApolloProviderProps = {
  children: React.ReactNode
  graphqlUri: string
}
function AuthorizedApolloProvider({
  children,
  graphqlUri,
}: AuthorizedApolloProviderProps) {
  const { getAccessTokenSilently } = useAuth0()
  const httpLink = createHttpLink({
    uri: graphqlUri,
  })
  const authLink = setContext(async () => {
    const token = await getAccessTokenSilently()
    // Keep for debugging
    // console.log('Token', token)
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  })
  const [client, setClient] = useState<ApolloClient<unknown>>(null)
  if (!client) {
    setClient(
      new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
        connectToDevTools: true,
      })
    )
  }
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
