import React from 'react'
import { Helmet } from 'react-helmet'

interface PageTitleProps {
  children: string
}

const PageTitle = (props: PageTitleProps) => {
  return (
    <Helmet>
      <title>{props.children} | Arrakis</title>
    </Helmet>
  )
}

export default PageTitle
