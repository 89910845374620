import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Typography,
} from '@mui/material'
import EntityFilter from './EntityFilter'
import { EntityTableDispatchAction, EntityTableFilterState } from '../EntityTable/types'
import { reduce, Dictionary } from 'lodash'
import { EntityListDisplaySettings, FilterGroup } from '../../settings/types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface EntityFiltersProps {
  settings: EntityListDisplaySettings
  filterGroups: FilterGroup[]
  filterState: Dictionary<EntityTableFilterState>
  filterDispatch: React.Dispatch<EntityTableDispatchAction>
}

interface FilterGrouptypeSwitchProps {
  filterGroup: FilterGroup
  children: React.ReactNode
  filterState: Dictionary<EntityTableFilterState>
}

const FilterGroupTypeSwitch = (props: FilterGrouptypeSwitchProps) => {
  const { filterGroup, children, filterState } = props

  const groupActiveFilterCount = reduce(
    filterGroup.filters,
    (count, filter) => count + (filterState[filter].active ? 1 : 0),
    0
  )

  switch (filterGroup.type) {
    case 'collapsible':
      return (
        <Accordion
          disableGutters
          elevation={0}
          sx={{
            borderBottom: 1,
            borderTop: 0,
            borderColor: 'grey.200',
            '&.Mui-expanded': {
              borderTop: 1,
              borderColor: 'grey.300',
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              variant={'overline'}
              color={'primary'}
              sx={{ width: '80%', flexShrink: 0 }}
            >
              {filterGroup.title}
            </Typography>
            {groupActiveFilterCount > 0 && <Chip label={groupActiveFilterCount} />}
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      )
    case 'plain':
      return (
        <>
          <Box px={'1rem'}>
            <Typography variant={'h6'}>{filterGroup.title}</Typography>
          </Box>
          {children}
        </>
      )
  }
}

const EntityFilters = (props: EntityFiltersProps) => {
  const { filterState, filterGroups, filterDispatch } = props

  return (
    <Box pb={'20rem'} pt={'0.5rem'}>
      {filterGroups.map((filterGroup) => (
        <FilterGroupTypeSwitch filterGroup={filterGroup} filterState={filterState}>
          {filterGroup.filters.map((filterId) => (
            <Box key={filterId} mb={'1rem'}>
              <EntityFilter
                filterState={filterState[filterId]}
                filterDispatch={filterDispatch}
              />
            </Box>
          ))}
        </FilterGroupTypeSwitch>
      ))}
    </Box>
  )
}

export default EntityFilters
