import React from 'react'
import { EntityTableDispatchAction, EntityTableFilterState } from '../EntityTable/types'
import Categorical from './Filters/Categorical'
import Integer from './Filters/Integer'
import Search from './Filters/Search'
import Boolean from './Filters/Boolean'

export interface EntityTableFilterProps {
  filterState: EntityTableFilterState
  filterDispatch: React.Dispatch<EntityTableDispatchAction>
}

const EntityFilter = (props: EntityTableFilterProps) => {
  switch (props.filterState.field_type) {
    case 'CATEGORICAL':
      return (
        <Categorical
          filterDispatch={props.filterDispatch}
          filterState={props.filterState}
        />
      )
    case 'INTEGER':
      return (
        <Integer
          filterDispatch={props.filterDispatch}
          filterState={props.filterState}
        />
      )
    case 'SEARCH':
      return (
        <Search filterDispatch={props.filterDispatch} filterState={props.filterState} />
      )
    case 'BOOLEAN':
      return (
        <Boolean
          filterDispatch={props.filterDispatch}
          filterState={props.filterState}
        />
      )
  }
}

export default EntityFilter
