import { EntityListDisplaySettings } from '../../settings/types'
import PageTitle from '../Core/PageTitle'
import { Box, Chip, Stack, Typography } from '@mui/material'
import EntityFilters from '../EntityFilters/EntityFilters'
import { DRAWER_WIDTH } from '../Core/constants'
import EntityTable from './EntityTable'
import { useCallback, useEffect, useReducer, useState } from 'react'
import { reducer } from './filters'
import { keyBy, merge } from 'lodash'
import { createBrowserHistory } from 'history'
import { useNavigate } from 'react-router-dom'
import { GqlWhereExpression } from './types'

interface EntityTableLayoutProps {
  settings: EntityListDisplaySettings
  preFilter?: GqlWhereExpression
}

export const EntityTableWithFilters = ({
  settings,
  preFilter,
}: EntityTableLayoutProps) => {
  const [filterGroups] = useState(settings.filter_groups)

  // const history = createBrowserHistory()
  // const navigate = useNavigate()

  const defaultFilterState = keyBy(settings.filters, 'field_id')

  // const [historicalState] = useState(history.location.state || defaultFilterState)

  const [filterState, filterDispatch] = useReducer(
    reducer,
    merge({}, defaultFilterState /*, historicalState['filterState']*/)
  )
  // const resetFilters = useCallback(() => {
  //   filterDispatch({ type: 'RESET_FILTERS', value: defaultFilterState })
  //   navigate(null, { replace: true, state: defaultFilterState })
  // }, [defaultFilterState, navigate])

  const numActiveFilters = Object.values(filterState).filter(
    (state) => state.active
  ).length

  // useEffect(() => {
  //   navigate(null, {
  //     replace: true,
  //     state: { filterState },
  //   })
  // }, [filterState, navigate])

  // console.log('FILTER STATE:', filterState)
  return (
    <>
      <PageTitle>{settings.display_name}</PageTitle>
      <Box sx={{ display: 'flex' }}>
        <Box
          position={'fixed'}
          width={DRAWER_WIDTH}
          overflow={'auto'}
          maxHeight={'100vh'}
        >
          <Box px={'1rem'} sx={{ borderBottom: 1, borderColor: 'grey.300' }}>
            <Stack direction="row" pr={'1rem'} py={'0.75rem'}>
              <Box>
                <Typography variant={'h6'}>Filters</Typography>
              </Box>
              {numActiveFilters > 0 && (
                <Box px={'0.75rem'}>
                  <Chip label={numActiveFilters} /*onDelete={resetFilters}*/ />
                </Box>
              )}
            </Stack>
          </Box>
          <EntityFilters
            settings={settings}
            filterGroups={filterGroups}
            filterState={filterState}
            filterDispatch={filterDispatch}
          />
        </Box>
        <Box
          sx={{
            borderLeft: '1px solid #eeeeee',
          }}
          width={`calc(100vw - ${DRAWER_WIDTH})`}
          position={'fixed'}
          overflow={'auto'}
          maxHeight={`calc(100vh - 2.5rem)`}
          ml={DRAWER_WIDTH}
        >
          <EntityTable
            settings={settings}
            filterState={filterState}
            preFilter={preFilter}
          />
        </Box>
      </Box>
    </>
  )
}
