import { Box, ClickAwayListener, Typography } from '@mui/material'
import React from 'react'
import { EntityTableFilterProps } from './EntityFilter'

interface EntityTableFilterTitleProps extends EntityTableFilterProps {
  onActiveClick?: () => void
}

const EntityTableFilterTitle = (props: EntityTableFilterTitleProps) => {
  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'baseline'}>
      <Typography variant={'subtitle2'} gutterBottom>
        {props.filterState.field_name}
      </Typography>
    </Box>
  )
}

interface EntityTableFilterContainerProps extends EntityTableFilterProps {
  children: React.ReactNode
  onClickAway?: () => void
  alwaysActive?: boolean
}

const EntityFilterContainer = (props: EntityTableFilterContainerProps) => {
  const handleActiveClick = props.alwaysActive
    ? null
    : () => {
        props.filterDispatch({
          type: 'ENABLE_TOGGLED',
          filterId: props.filterState.field_id,
        })
      }
  return (
    <ClickAwayListener
      onClickAway={props.onClickAway}
      mouseEvent={props.onClickAway ? 'onClick' : false}
    >
      <Box px={'1rem'}>
        {props.filterState.field_name && (
          <EntityTableFilterTitle {...props} onActiveClick={handleActiveClick} />
        )}
        <Box>{props.children}</Box>
      </Box>
    </ClickAwayListener>
  )
}
export default EntityFilterContainer
