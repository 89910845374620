import {
  Box,
  Chip,
  IconButton,
  ListItem,
  Paper,
  Typography,
  useTheme,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import React, { useRef, useState } from 'react'
import produce from 'immer'
import { CategoricalFieldTypeOption } from '../../EntityTable/types'
import { Dictionary } from 'lodash'
import { useApolloClient, gql } from '@apollo/client'
import { EntityTableCategoricalFilterProps } from './Categorical'

interface EntityTableCategoricalFilterChipInputProps
  extends EntityTableCategoricalFilterProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleOptionClick: (string) => void
}

const CategoricalChipInput = (props: EntityTableCategoricalFilterChipInputProps) => {
  const apolloClient = useApolloClient()
  const chipContainerRef = useRef()
  const [optionChipBottomVisibleMap, setOptionChipVisibleMap] = useState<
    Dictionary<boolean>
  >({})
  const theme = useTheme()
  const selectedIds = Object.keys(
    props.filterState.categorical_field.selected_options_map
  )
  const selectedOptions = selectedIds.map(getOptionById)

  const chipContainer = chipContainerRef.current as HTMLElement

  function setOptionChipRef(optionId, chipElement: HTMLElement) {
    if (!chipElement) return

    const chipContainerRect = chipContainer.getBoundingClientRect()
    const chipRect = chipElement.getBoundingClientRect()
    const chipBottomOffset = chipElement.offsetTop + chipRect.height
    setOptionChipVisibleMap((prevState) =>
      produce(prevState, (draft) => {
        draft[optionId] = chipBottomOffset < chipContainerRect.height
      })
    )
  }

  const hiddenOptionCount = Object.entries(optionChipBottomVisibleMap).filter(
    ([optionId, visible]) =>
      !visible && optionId in props.filterState.categorical_field.selected_options_map
  ).length

  const handleDeselectAllOptions = () => {
    props.filterDispatch({
      type: 'ALL_OPTIONS_DESELECTED',
      filterId: props.filterState.field_id,
    })
    props.setOpen(false)
  }

  // TODO: Simplify this by getting stuff directly from the cache by id
  function getOptionById(optionId: string) {
    return apolloClient.cache.readFragment({
      id: apolloClient.cache.identify({
        __typename: 'categorical_field_option',
        id: optionId,
      }),
      fragment: gql`
        fragment field_short_name on categorical_field_option {
          id
          long_name
          short_name
        }
      `,
    }) as CategoricalFieldTypeOption
  }

  return (
    <Box position={'relative'}>
      <Paper
        ref={chipContainerRef}
        variant={'outlined'}
        sx={{
          border: 0,
          display: 'flex',
          justifyContent: 'left',
          alignContent: 'flex-start',
          flexWrap: 'wrap',
          listStyle: 'none',
          py: '0.5rem',
          px: '0.5rem',
          pb: '1rem',
          m: 0,
          minHeight: '2.5rem',
          maxHeight: '12.75rem',
          cursor: 'text',
          borderBottomRightRadius: props.open ? 0 : theme.shape.borderRadius,
          borderBottomLeftRadius: props.open ? 0 : theme.shape.borderRadius,
        }}
        component="ul"
        onClick={() => props.setOpen(true)}
      >
        {selectedOptions.map(
          (option) =>
            chipContainerRef.current && (
              <ListItem
                component={'li'}
                sx={{
                  p: 0,
                  pr: '.25rem',
                  pb: '.25rem',
                  width: 'inherit',
                  maxWidth: 1,
                }}
                key={option.id}
                ref={(el) => setOptionChipRef(option.id, el)}
              >
                <Chip
                  size={'small'}
                  label={option.short_name}
                  onDelete={() => props.handleOptionClick(option.id)}
                  sx={{
                    visibility: optionChipBottomVisibleMap?.[option.id]
                      ? 'visible'
                      : 'hidden',
                  }}
                />
              </ListItem>
            )
        )}
        {hiddenOptionCount > 0 && (
          <ListItem
            dense
            sx={{ position: 'absolute', bottom: '0.25rem', p: '0.25rem' }}
          >
            <Typography variant={'body2'} color={'text.secondary'}>
              and {hiddenOptionCount} more
            </Typography>
          </ListItem>
        )}
      </Paper>
      {selectedOptions.length > 0 && (
        <IconButton
          sx={{ position: 'absolute', bottom: '0.25rem', right: '0.25rem' }}
          size={'small'}
          onClick={handleDeselectAllOptions}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
    </Box>
  )
}

export default CategoricalChipInput
