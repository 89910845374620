import { AppBar, Box, Button, Toolbar } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as ArrakisLogo } from '../../image/arrakis-logo.svg'
import { ENTITY_SETTINGS } from '../../settings/entity-settings'

const ENTITY_LINKS = Object.entries(ENTITY_SETTINGS).map(([entityName, settings]) => ({
  displayName: settings.display_name,
  to: '/view/' + entityName,
}))

const Header = () => {
  return (
    <>
      <AppBar
        position={'fixed'}
        color={'default'}
        elevation={0}
        sx={{ borderBottom: 'solid 1px', borderBottomColor: 'grey.300' }}
      >
        <Toolbar
          variant={'dense'}
          sx={{ px: '0.5rem', height: '3rem', display: 'flex' }}
          disableGutters
        >
          <Box sx={{ flexGrow: 1, py: '0.5rem' }} display={'flex'}>
            <Box
              component={ArrakisLogo}
              height={'2rem'}
              mr={'0.5rem'}
              py={'0.125rem'}
            />
            <Button component={NavLink} to={'/view'} sx={{ display: 'inline-block' }}>
              Explore
            </Button>
          </Box>
          <Button
            sx={{
              '&.active': {
                display: 'none',
              },
            }}
            to={'/settings'}
            component={NavLink}
          >
            Settings
          </Button>
        </Toolbar>
      </AppBar>
      {
        <AppBar
          position={'fixed'}
          color={'default'}
          elevation={0}
          sx={{
            top: '3rem',
            px: '1rem',
            py: '.125rem',
            borderBottom: 'solid 1px',
            borderTop: 'solid 1px',
            borderBottomColor: 'grey.300',
            borderTopColor: 'grey.300',
          }}
        >
          <Box>
            {ENTITY_LINKS.map((entityLink) => (
              <Button
                sx={{ flex: 0 }}
                to={entityLink.to}
                component={NavLink}
                size={'small'}
              >
                {entityLink.displayName}
              </Button>
            ))}
          </Box>
        </AppBar>
      }
    </>
  )
}

export default Header
