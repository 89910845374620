import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

export const UserTracking = () => {
  const { user } = useAuth0()

  useEffect(() => {
    if (user) {
      // Hotjar: https://insights.hotjar.com/settings/user-attributes?site-id=3282776
      window?.['hj']('identify', user['sub'], {
        customerId: user.customer_id,
        email: user.email,
      })
    }
  }, [user])

  return <></>
}
