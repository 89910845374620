import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { HEADER_HEIGHT } from '../Core/constants'

export const PageContainer = () => (
  <Box px={0} mt={HEADER_HEIGHT}>
    <Outlet />
  </Box>
)

export default PageContainer
