import { useQuery } from '@apollo/client'
import {
  ChevronLeft as BackIcon,
  ChevronRight as ForwardsIcon,
} from '@mui/icons-material'
import {
  Box,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Dictionary } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Entity, EntityListDisplaySettings } from '../../settings/types'
import {
  CategoricalFieldTypeOption,
  EntityTableFilterState,
  GqlWhereExpression,
} from './types'

import { makeWhereQuery, makeSearchArgs} from './filters'
import { DRAWER_WIDTH } from '../Core/constants'
import { FormattedNumber } from '../Core/FormattedNumber'

interface Data {
  semSearch: {
    entities: Entity[]
    entity_aggregate: {
      aggregate: {
        count: number
      }
    }
  }
  entities?: Entity[]
  entity_aggregate?: {
    aggregate: {
      count: number
    }
  }
  categorical_field_type_options: {
    categorical_field_type_id: string
    options: CategoricalFieldTypeOption[]
    option_map: Dictionary<CategoricalFieldTypeOption>
  }[]
}

type Variables = {
  where
  search?: string
  similarSearch?: string
  limit: number
  offset: number
}

interface EntityTableProps {
  // entityName: string
  filterState: Dictionary<EntityTableFilterState>
  settings: EntityListDisplaySettings
  preFilter?: GqlWhereExpression
}

const PAGE_SIZE = 10

function getPaginationArgs(page: number) {
  return {
    offset: PAGE_SIZE * page,
    limit: PAGE_SIZE,
  }
}

interface Pagination {
  buttons: {
    upperDisabled: boolean
    lowerDisabled: boolean
  }
  range: {
    lower: number
    upper: number
  }
}

function getPaginationTableData(
  page: number,
  pageSize: number,
  totalRecordCount: number
): Pagination {
  if (totalRecordCount === 0) return null
  const totalPages = Math.ceil(totalRecordCount / pageSize)
  const lower = page * pageSize + 1
  const upperUncapped = (page + 1) * pageSize
  const upper = upperUncapped > totalRecordCount ? totalRecordCount : upperUncapped
  const upperDisabled = page === totalPages - 1
  const lowerDisabled = page === 0
  return {
    buttons: {
      lowerDisabled,
      upperDisabled,
    },
    range: {
      lower,
      upper,
    },
  }
}

const EntityTable = (props: EntityTableProps) => {
  const { settings, preFilter } = props
  const displayName = settings.display_name
  const [cachedData, setCachedData] = useState<Data>(null)
  const [page, setPage] = useState(0)
  const [pagination, setPagination] = useState<Pagination>(null)
  const tableColumns = settings.columns

  const whereQuery = makeWhereQuery(props.filterState, preFilter)
  const {
    loading: queryLoading,
    error,
    data,
  } = useQuery<Data, Variables>(settings.query, {
    variables: {
      where: whereQuery,
      search: makeSearchArgs(props.filterState).search,
      similarSearch: makeSearchArgs(props.filterState).similarSearch,
      ...getPaginationArgs(page),
    },
  })

  const loading = queryLoading || !cachedData

  useEffect(() => {
    if (data) {
      const recordCount =
        displayName == 'Accounts'
          ? data.semSearch.entity_aggregate.aggregate.count
          : data.entity_aggregate.aggregate.count
      setCachedData(data)
      setPagination(getPaginationTableData(page, PAGE_SIZE, recordCount))
    }
  }, [data, page])

  const displayData = data ?? cachedData
  const entities =
    displayName == 'Accounts' ? displayData?.semSearch.entities : displayData?.entities
  const entityAggregate =
    displayName == 'Accounts'
      ? displayData?.semSearch.entity_aggregate
      : displayData?.entity_aggregate

  if (error) return <pre>{JSON.stringify(error.message)}</pre>

  return (
    <>
      {loading && (
        <Box position={'fixed'} sx={{ zIndex: 3, width: 1, height: '0.5rem' }}>
          <LinearProgress sx={{ position: 'relative', top: '1.8rem' }} />
        </Box>
      )}
      <Box position={'relative'}>
        <Table stickyHeader sx={{ mb: '18rem' }}>
          <TableHead>
            <TableRow>
              {tableColumns.map((column) => (
                <TableCell
                  size={'small'}
                  key={column.title}
                  variant={'head'}
                  {...column.header_props}
                  height={'1rem'}
                  sx={{ px: '0.5rem' }}
                >
                  <Typography
                    variant={'overline'}
                    color={'textSecondary'}
                    sx={{
                      zIndex: 10,
                      lineHeight: 1,
                      m: 0,
                      px: 0,
                      py: '0.125rem',
                      display: 'block',
                    }}
                  >
                    {column.title}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {entities?.map((entity) => (
              <TableRow key={entity.id}>
                {tableColumns.map((column, colIdx, columns) => (
                  <React.Fragment key={column.title}>
                    {column.render({
                      entity,
                      isFirst: colIdx === 0,
                      isLast: colIdx === columns.length - 1,
                    })}
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box
          borderTop={'solid 1px'}
          borderColor={'grey.300'}
          bgcolor={'grey.50'}
          py={'0.5rem'}
          px={'1rem'}
          position={'fixed'}
          bottom={0}
          width={`calc(100% - ${DRAWER_WIDTH})`}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography color={'textSecondary'}>
            {!loading && (
              <>
                Matching
                <FormattedNumber
                  value={entityAggregate?.aggregate.count}
                  sx={{ fontWeight: 'medium', px: '0.3em' }}
                />
                {settings.display_name}
              </>
            )}
            {loading && 'Loading...'}
          </Typography>
          <Box display={'flex'} alignItems={'center'} height={'2rem'}>
            {pagination && (
              <>
                <Box mr={'1rem'}>
                  <Typography>
                    Showing results {pagination.range.lower} - {pagination.range.upper}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    aria-label="back"
                    onClick={() => setPage((prevPage) => prevPage - 1)}
                    disabled={pagination.buttons.lowerDisabled}
                  >
                    <BackIcon />
                  </IconButton>
                  <IconButton
                    aria-label="next"
                    onClick={() => setPage((prevPage) => prevPage + 1)}
                    disabled={pagination.buttons.upperDisabled}
                  >
                    <ForwardsIcon />
                  </IconButton>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default EntityTable
