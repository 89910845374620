import { Account } from '../../settings/types'
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import EntityTableFieldList from '../EntityTable/EntityTableFieldList'
import { FormattedNumber } from '../Core/FormattedNumber'
import { EntityTableWithFilters } from '../EntityTable/EntityTableWithFilters'
import { ENTITY_SETTINGS } from '../../settings/entity-settings'

const Datapoint = ({ title, children }) => (
  <>
    <Typography component={'div'} variant={'overline'} color={'textSecondary'}>
      {title}
    </Typography>
    {children}
  </>
)

const OverviewTab = () => {
  const { account } = useOutletContext<{ account }>()
  const cmsSpecialties = account.provider_cms_specialtyz.map(
    ({ count, cms_specialty }) => {
      return { value: `${count} — ${cms_specialty.long_name}` }
    }
  )
  const accountAffluence = account.affluent_ratingz[0].affluent_rating.long_name

  return (
    <Box sx={{ flexGrow: 1, px: '1em' }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Datapoint title={'Providers'}>
            <EntityTableFieldList
              rowLimit={4}
              itemKey={'value'}
              items={cmsSpecialties}
            />
            {account.provider_count && (
              <span>{account.provider_count} total providers</span>
            )}
          </Datapoint>
        </Grid>
        <Grid item xs={3}>
          <EntityTableFieldList
            title={'Primary Account Specialty'}
            itemKey={'nucc_taxonomy.long_name'}
            items={account.nucc_taxonomyz}
          ></EntityTableFieldList>
        </Grid>
        <Grid item xs={3}>
          <Datapoint title={'Annual Medicare FFS Claims'}>
            <FormattedNumber
              variant={'body'}
              value={account.cms_ffs_claim_annual_count}
            />
          </Datapoint>

          <Datapoint title={'Annual Medicare Beneficiaries'}>
            <FormattedNumber
              variant={'body'}
              value={account.cms_beneficiary_annual_count}
            />
          </Datapoint>
        </Grid>
        <Grid item xs={3}>
          <Datapoint title={'Technographics'}>
            <EntityTableFieldList
              itemKey={'technographic.long_name'}
              items={account.technographicz}
            ></EntityTableFieldList>
          </Datapoint>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant={'overline'}
            color={'textSecondary'}
            sx={{ lineHeight: 1 }}
          >
            Patient demographics
          </Typography>
          <Box>{accountAffluence} patient affluence</Box>
          <Box>
            Avg. Location Zip Code Wealth: $
            <FormattedNumber value={account.average_location_household_income_usd} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export const ContactsTab = () => {
  const { account } = useOutletContext<{ account }>()
  const contactListSettings = ENTITY_SETTINGS['contact'].list
  const contactListSettingsNoAccountIdColumn = {
    ...contactListSettings,
    columns: contactListSettings.columns.filter((column) => column.title != 'Account'),
  }
  return (
    <EntityTableWithFilters
      settings={contactListSettingsNoAccountIdColumn}
      preFilter={{ account_id: { _eq: account.id } }}
    />
  )
}

export const LocationsTab = () => {
  const { account } = useOutletContext<{ account }>()
  return (
    <List>
      {account.locationz.map((location) => (
        <Typography>
          <ListItem>{location.location.address}</ListItem>
          <Divider component="li" />
        </Typography>
      ))}
    </List>
  )
}

export const AccountDetailsTabContainer = () => {
  const { tabId } = useOutletContext<{ tabId }>()
  switch (tabId) {
    case 'overview':
      return <OverviewTab />
    case 'contacts':
      return <ContactsTab />
    case 'locations':
      return <LocationsTab />
    default:
      throw new Error(`Unknown tab identifier: '${tabId}'`)
  }
}

export const AccountDetailsTabsLayout = ({ account }) => {
  const { tabId } = useParams()

  return (
    <>
      <Tabs value={tabId} sx={{ borderBottom: 1, borderColor: '#cacaca' }}>
        <Tab label="Overview" value="overview" component={Link} to={'overview'} />
        <Tab label="Contacts" value="contacts" component={Link} to={'contacts'} />
        <Tab label="Locations" value="locations" component={Link} to={'locations'} />
      </Tabs>

      <Outlet context={{ tabId, account }} />
    </>
  )
}

interface AccountDetailsTabsProps {
  account: Account
}

export const AccountDetailsTabs = ({ account }: AccountDetailsTabsProps) => (
  <Routes>
    <Route path={''} element={<AccountDetailsTabsLayout account={account} />}>
      <Route index element={<Navigate to={'overview'} replace />} />
      <Route path={':tabId'} element={<AccountDetailsTabContainer />} />
    </Route>
  </Routes>
)
// AccountDetailsTabs.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: 'AccountDetailTabs',
// }
