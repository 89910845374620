import {
  Box,
  IconButton,
  Link,
  TableCell,
  TableCellProps,
  Tooltip,
  Typography,
} from '@mui/material'
import EntityTableFieldList from '../component/EntityTable/EntityTableFieldList'
import { Link as RouterLink } from 'react-router-dom'
import { gql } from '@apollo/client'
import { BooleanFilterOptions } from '../component/EntityTable/types'
import { Account, EntitySettings } from './types'
import { Dictionary, orderBy } from 'lodash'
import { AccountDetails } from '../component/AccountDetails/AccountDetails'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import LinkIcon from '@mui/icons-material/Link'
import { FormattedNumber } from '../component/Core/FormattedNumber'
import { Email, Phone } from '../component/EntityTable/columns'

interface RowRenderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entity: any
}

const MyTableCell = (props: TableCellProps & RowRenderProps) => (
  <TableCell {...props} sx={{ p: '0.5rem', verticalAlign: 'top' }} />
)

export const ENTITY_SETTINGS: Dictionary<EntitySettings> = {
  account: {
    display_name: 'Accounts',
    item: {
      display_name: 'Account',
      query: gql`
        query GetAccount($entityId: String!) {
          account: account_by_pk(id: $entityId) {
            id
            name: doing_business_as_name
            doing_business_as_name
            account_classz {
              account_class {
                id
              }
            }
            location_count
            provider_count
            technographicz {
              technographic {
                long_name
              }
            }
            nucc_taxonomyz {
              nucc_taxonomy {
                long_name
              }
            }
            locationz {
              location {
                address
                zip
                us_state_id
              }
            }

            domainz {
              domain {
                long_name
                short_name
              }
            }

            cms_ffs_claim_annual_count
            cms_beneficiary_annual_count
            average_location_household_income_usd

            provider_cms_specialtyz(order_by: { count: desc }) {
              count
              cms_specialty {
                long_name
              }
            }

            affluent_ratingz {
              affluent_rating {
                long_name
              }
            }

            independencez {
              independence {
                long_name
              }
            }
          }
        }
      `,
      render: (entity: unknown) => {
        const account = entity as Account

        return <AccountDetails account={account} />
      },
      details: {
        locations: {
          query: gql`
            query GetAccount($entityId: String!) {
              account: account_by_pk(id: $entityId) {
                id
                name: doing_business_as_name
                doing_business_as_name
                locations: location_us_statez {
                  us_state {
                    long_name
                  }
                }
              }
            }
          `,
          render: () => <Box>Locations</Box>,
        },
      },
    },
    list: {
      display_name: 'Accounts',
      query: gql`
        query MyQuery(
          $search: String
          $similarSearch: String
          $limit: Int
          $offset: Int
          $where: MyQueryAccountBoolExp
        ) {
          semSearch(
            limit: $limit
            search: $search
            similarSearch: $similarSearch
            offset: $offset
            where: $where
          ) {
            entities {
              id
              doing_business_as_name
              location_count
              provider_count
              doctor_count
              contact_count
              domainz {
                domain {
                  short_name
                  long_name
                }
              }
              location_us_statez {
                us_state {
                  long_name
                  short_name
                }
                count
              }
              nucc_taxonomyz {
                nucc_taxonomy {
                  long_name
                  short_name
                }
              }
              account_classz {
                account_class {
                  long_name
                  short_name
                }
              }
              contactz_aggregate {
                aggregate {
                  count
                }
              }
            }
            entity_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      `,
      filter_groups: [
        { title: '', type: 'plain', filters: ['search', 'similar_search'] },
        {
          title: 'Size',
          type: 'collapsible',
          filters: [
            'doctor_count',
            'provider_count',
            'contact_count',
            'location_count',
            'independencez',
          ],
        },
        {
          title: 'Specialties',
          type: 'collapsible',
          filters: [
            'provider_cms_specialtyz',
            'provider_nucc_taxonomyz',
            'nucc_taxonomyz',
            'account_classz',
          ],
        },
        {
          title: 'Locations',
          type: 'collapsible',
          filters: ['location_us_statez', 'cityz'],
        },
        {
          title: 'Patient Demographics',
          type: 'collapsible',
          filters: ['affluent_ratingz', 'average_location_household_income_usd'],
        },
        {
          title: 'Technographics',
          type: 'collapsible',
          filters: ['technographicz'],
        },
      ],
      filters: [
        {
          field_type: 'SEARCH',
          field_id: 'search',
          field_name: '',
          active: false,
          search_field: {},
          placeholder: 'Search by term',
        },
        {
          field_type: 'SEARCH',
          field_id: 'similar_search',
          field_name: '',
          active: false,
          search_field: {},
          placeholder: 'Search by similar account',
        },
        {
          field_type: 'INTEGER',
          field_id: 'doctor_count',
          field_name: 'Doctors',
          active: false,
          integer_field: {},
        },
        {
          field_type: 'INTEGER',
          field_id: 'provider_count',
          field_name: 'All Providers',
          active: false,
          integer_field: {},
        },
        {
          field_type: 'INTEGER',
          field_id: 'contact_count',
          field_name: 'Contacts',
          active: false,
          integer_field: {},
        },
        {
          field_type: 'INTEGER',
          field_id: 'location_count',
          field_name: 'Locations',
          active: false,
          integer_field: {},
        },
        {
          field_type: 'CATEGORICAL',
          field_id: 'provider_cms_specialtyz',
          field_name: 'Provider Specialty (simple)',
          active: false,
          categorical_field: {
            categorical_field_type: 'cms_specialty',
            selected_options_map: {},
          },
        },
        {
          field_type: 'CATEGORICAL',
          field_id: 'provider_nucc_taxonomyz',
          field_name: 'Provider Specialty (all)',
          active: false,
          categorical_field: {
            categorical_field_type: 'nucc_taxonomy',
            selected_options_map: {},
          },
        },
        {
          field_type: 'CATEGORICAL',
          field_id: 'nucc_taxonomyz',
          field_name: 'Primary Account Specialty (self-reported)',
          active: false,
          categorical_field: {
            categorical_field_type: 'nucc_taxonomy',
            selected_options_map: {},
          },
        },
        {
          field_type: 'CATEGORICAL',
          field_id: 'account_classz',
          field_name: 'Arrakis Account Class',
          active: false,
          categorical_field: {
            categorical_field_type: 'account_class',
            selected_options_map: {},
          },
        },
        {
          field_type: 'CATEGORICAL',
          field_id: 'location_us_statez',
          field_name: 'States',
          active: false,
          categorical_field: {
            categorical_field_type: 'us_state',
            selected_options_map: {},
          },
        },
        {
          field_type: 'CATEGORICAL',
          field_id: 'cityz',
          field_name: 'Cites',
          active: false,
          categorical_field: {
            categorical_field_type: 'city',
            selected_options_map: {},
          },
        },
        {
          field_type: 'CATEGORICAL',
          field_id: 'technographicz',
          field_name: 'Technographics',
          active: false,
          categorical_field: {
            categorical_field_type: 'technographic',
            selected_options_map: {},
          },
        },
        {
          field_type: 'CATEGORICAL',
          field_id: 'affluent_ratingz',
          field_name: 'Patient Affluence',
          active: false,
          categorical_field: {
            categorical_field_type: 'affluent_rating',
            selected_options_map: {},
          },
        },
        {
          field_type: 'INTEGER',
          field_id: 'average_location_household_income_usd',
          field_name: 'Avg. Location Zip Code Wealth',
          active: false,
          integer_field: {},
        },
        {
          field_type: 'CATEGORICAL',
          field_id: 'independencez',
          field_name: 'Independence',
          active: false,
          categorical_field: {
            categorical_field_type: 'independence',
            selected_options_map: {},
          },
        },
      ],
      columns: [
        {
          title: 'Name',
          header_props: {
            align: 'left',
          },
          render: (props: RowRenderProps) => (
            <MyTableCell sx={{ p: '0.5rem', verticalAlign: 'top' }} {...props}>
              {/*<Typography*/}
              {/*  sx={{ fontSize: '0.75rem' }}*/}
              {/*  color={'textSecondary'}*/}
              {/*  gutterBottom*/}
              {/*>*/}
              {/*  {props.entity.id}*/}
              {/*</Typography>*/}
              <Link
                component={RouterLink}
                to={`/view/account/${props.entity.id}`}
                variant={'subtitle1'}
                color={'primary'}
                gutterBottom
              >
                {props.entity.doing_business_as_name}
              </Link>
              <Box>
                {props.entity.domainz.map(({ domain }) => (
                  <Tooltip title={domain.short_name}>
                    <IconButton
                      component={Link}
                      href={domain.long_name}
                      target={'_blank'}
                    >
                      <LinkIcon sx={{ transform: 'rotate(-45deg)' }} />
                    </IconButton>
                  </Tooltip>
                ))}
              </Box>
            </MyTableCell>
          ),
        },
        // {
        //   title: 'Arrakis Classes',
        //   headerProps: {
        //     align: 'left',
        //   },
        //   render: (props: RowRenderProps) => (
        //     <MyTableCell sx={{ p: 0, verticalAlign: 'top' }} align={'left'} {...props}>
        //       <EntityTableFieldList
        //         itemKey={'account_class.long_name'}
        //         items={props.entity.account_classz}
        //       />
        //     </MyTableCell>
        //   ),
        // },
        {
          title: 'Primary Account Specialty',
          header_props: {
            align: 'left',
          },
          render: (props: RowRenderProps) => (
            <MyTableCell sx={{ p: 0, verticalAlign: 'top' }} align={'left'} {...props}>
              <EntityTableFieldList
                itemKey={'nucc_taxonomy.long_name'}
                items={props.entity.nucc_taxonomyz}
              />
            </MyTableCell>
          ),
        },
        {
          title: '# Locations',
          header_props: {
            align: 'right',
          },
          render: (props: RowRenderProps) => (
            <MyTableCell sx={{ p: 0, verticalAlign: 'top' }} align={'right'} {...props}>
              <FormattedNumber value={props.entity.location_count} />
              {/*<EntityTableFieldList*/}
              {/*  itemKey={'us_state.long_name'}*/}
              {/*  items={props.entity.location_us_statez}*/}
              {/*/>*/}
            </MyTableCell>
          ),
        },
        {
          title: '# Providers',
          header_props: {
            align: 'right',
          },
          render: (props: RowRenderProps) => (
            <MyTableCell sx={{ p: 0, verticalAlign: 'top' }} align={'right'} {...props}>
              <FormattedNumber value={props.entity.provider_count} />
            </MyTableCell>
          ),
        },
        {
          title: '# Contacts',
          header_props: {
            align: 'right',
          },
          render: (props: RowRenderProps) => (
            <MyTableCell sx={{ p: 0, verticalAlign: 'top' }} align={'right'} {...props}>
              <FormattedNumber value={props.entity.contact_count} />
            </MyTableCell>
          ),
        },
      ],
    },
  },
  contact: {
    display_name: 'Contacts',
    list: {
      display_name: 'Contacts',
      query: gql`
        query contactsTable(
          $where: contact_bool_exp
          $search: String
          $searchArgs: contact_search_args = {}
          $limit: Int
          $offset: Int
        ) {
          entities: contact_search(
            args: {search: $search}
            order_by: { order_key: asc }
            limit: $limit
            offset: $offset
            where: $where
          ) {
            id
            name_formatted
            title
            linkedin_urls
            accountz {
              account {
                id
                doing_business_as_name
              }
            }
            phonez {
              phone {
                phone_number
                types
              }
            }
            emailz {
              email {
                email
                verification_status
                type
              }
            }
            title_groupz {
              title_group {
                long_name
              }
            }
          }
          entity_aggregate: contact_search_aggregate(args: $searchArgs, where: $where) {
            aggregate {
              count
            }
          }
        }
      `,
      columns: [
        {
          title: 'Name',
          header_props: {
            align: 'left',
          },
          render: (props: RowRenderProps) => (
            <MyTableCell sx={{ p: '0.5rem', verticalAlign: 'top' }} {...props}>
              {/*<Typography*/}
              {/*  sx={{ fontSize: '0.75rem' }}*/}
              {/*  color={'textSecondary'}*/}
              {/*  gutterBottom*/}
              {/*>*/}
              {/*  {props.entity.id}*/}
              {/*</Typography>*/}
              <Typography>{props.entity.name_formatted}</Typography>
              {props.entity.linkedin_urls.length > 0 && (
                <Box>
                  <Tooltip title={props.entity.linkedin_urls[0]}>
                    <IconButton
                      component={Link}
                      href={props.entity.linkedin_urls[0]}
                      target={'_blank'}
                    >
                      <LinkedInIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </MyTableCell>
          ),
        },
        {
          title: 'Title',
          header_props: { align: 'left' },
          render: (props: RowRenderProps) => (
            <MyTableCell {...props}>
              <Typography sx={{ maxWidth: '30ch' }}>{props.entity.title}</Typography>
              {props.entity.title_groupz.map(({ title_group }, idx) => (
                <Typography key={idx} variant={'body2'} lineHeight={1.3}>
                  {title_group.long_name}
                </Typography>
              ))}
            </MyTableCell>
          ),
        },
        {
          title: 'Account',
          header_props: { align: 'left' },
          render: (props: RowRenderProps) => (
            <MyTableCell {...props}>
              {props.entity.accountz.map(({ account }) => (
                <Typography sx={{ maxWidth: '30ch' }}>
                  <Link component={RouterLink} to={`/view/account/${account.id}`}>
                    {account.doing_business_as_name}
                  </Link>
                </Typography>
              ))}
            </MyTableCell>
          ),
        },
        {
          title: 'Emails',
          header_props: { align: 'left' },
          render: (props: RowRenderProps) => (
            <MyTableCell {...props}>
              {orderBy(
                props.entity.emailz.map(({ email }) => email),
                ['verification_status', 'type', 'email'],
                ['desc', 'desc', 'asc']
              ).map(({ email, verification_status, type }, idx) => (
                <Email
                  key={idx}
                  email={email}
                  type={type}
                  verification_status={verification_status}
                />
              ))}
            </MyTableCell>
          ),
        },
        {
          title: 'Phones',
          header_props: { align: 'left' },
          render: (props: RowRenderProps) => (
            <MyTableCell {...props}>
              {props.entity.phonez.map(({ phone: { phone_number, types } }) => (
                <Phone phone={phone_number} types={types} />
              ))}
            </MyTableCell>
          ),
        },
      ],
      filter_groups: [
        {
          title: '',
          type: 'plain',
          filters: [
            'search',
            'has_emails',
            'has_business_emails',
            'has_personal_emails',
            'has_phones',
            'has_business_phone_numbers',
            'has_mobile_phone_numbers',
            'title_groupz',
          ],
        },
      ],
      filters: [
        {
          field_type: 'SEARCH',
          field_id: 'search',
          field_name: '',
          active: false,
          search_field: {},
        },
        {
          field_type: 'BOOLEAN',
          field_id: 'has_emails',
          field_name: 'Emails',
          active: false,
          label: 'Has at least 1 email',
          checked_value: 'true',
          unchecked_value: 'any',
          boolean_field: 'any' as BooleanFilterOptions,
        },
        {
          field_type: 'BOOLEAN',
          field_id: 'has_business_emails',
          field_name: '',
          active: false,
          label: 'Has business emails',
          checked_value: 'true',
          unchecked_value: 'any',
          boolean_field: 'any' as BooleanFilterOptions,
        },
        {
          field_type: 'BOOLEAN',
          field_id: 'has_personal_emails',
          field_name: '',
          active: false,
          label: 'Has personal emails',
          checked_value: 'true',
          unchecked_value: 'any',
          boolean_field: 'any' as BooleanFilterOptions,
        },
        {
          field_type: 'BOOLEAN',
          field_id: 'has_phones',
          field_name: 'Phones',
          active: false,
          label: 'Has at least 1 phone',
          checked_value: 'true',
          unchecked_value: 'any',
          boolean_field: 'any' as BooleanFilterOptions,
        },
        {
          field_type: 'BOOLEAN',
          field_id: 'has_business_phone_numbers',
          field_name: '',
          active: false,
          label: 'Has business phones',
          checked_value: 'true',
          unchecked_value: 'any',
          boolean_field: 'any' as BooleanFilterOptions,
        },
        {
          field_type: 'BOOLEAN',
          field_id: 'has_mobile_phone_numbers',
          field_name: '',
          active: false,
          label: 'Has mobile phones',
          checked_value: 'true',
          unchecked_value: 'any',
          boolean_field: 'any' as BooleanFilterOptions,
        },
        {
          field_type: 'CATEGORICAL',
          field_id: 'title_groupz',
          field_name: 'Title Groups',
          active: false,
          categorical_field: {
            categorical_field_type: 'title_group',
            selected_options_map: {},
          },
        },
      ],
    },
  },
}
