import React, { ChangeEventHandler, useCallback, useEffect, useState } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { EntityTableFilterProps } from '../EntityFilter'
import { EntityTableSearchFilterState } from '../../EntityTable/types'
import EntityFilterContainer from '../EntityFilterContainer'
import { debounce } from 'lodash'
import { SearchOutlined as SearchIcon } from '@mui/icons-material'

type EntityTableIntegerFilterProps = Omit<EntityTableFilterProps, 'filterState'> & {
  filterState: EntityTableSearchFilterState
}

function fromComponentValue(value: string) {
  if (value === '') return null
  return value
}

const Search = (props: EntityTableIntegerFilterProps) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setInputValue(props.filterState.search_field?.text || '')
  }, [props.filterState])

  // Need to disable linting because of lodash debounce and the linter can't
  // figure out what the dependencies are.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeDebounced = useCallback(
    debounce((newValue) => {
      props.filterDispatch({
        type: 'SEARCH_FILTER_TEXT_CHANGED',
        text: fromComponentValue(newValue),
        filterId: props.filterState.field_id,
      })
    }, 1000),
    [props]
  )

  const handleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (
    e
  ) => {
    setInputValue(e.target.value)
    handleChangeDebounced(e.target.value)
  }

  return (
    <EntityFilterContainer {...props} alwaysActive>
      <TextField
        fullWidth
        size={'small'}
        variant={'outlined'}
        placeholder={props.filterState.placeholder}
        sx={{ mr: '0.5rem' }}
        value={inputValue}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </EntityFilterContainer>
  )
}

export default Search
