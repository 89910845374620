import React from 'react'
import { Box, Grid, Link, List, ListItem, Typography } from '@mui/material'

import { Account } from '../../settings/types'
import { AccountDetailsTabs } from './AccountDetailsTabs'

interface AccountPageProps {
  account: Account
}

export const AccountDetails = (props: AccountPageProps) => {
  const account = props.account as Account

  const locationAgg: object = account.locationz.reduce((agg, { location }) => {
    const key = location.us_state_id
    agg[key] = agg[key] === undefined ? (agg[key] = 1) : (agg[key] += 1)
    return agg
  }, {})

  const locationAggArray = Object.keys(locationAgg)
    .map((k) => [k, locationAgg[k]]) // [state, location_count]
    .sort((a, b) => b[1] - a[1]) // Order states by location count

  const locationCount = account.locationz.length

  let locationAggDescription = `${locationCount} locations`
  if (account.locationz.length === 1) {
    // Single location, just using the address
    locationAggDescription = account.locationz[0].location.address
  } else if (locationAggArray.length < 4) {
    // Less than 3 states, listing all of them
    locationAggDescription +=
      ' in ' +
      locationAggArray
        .map(
          ([state, count]) =>
            `${state.toUpperCase()} ${
              locationAggArray.length > 1 && count > 1 ? '(' + count + ')' : ''
            }`
        )
        .join(', ')
  } else {
    // Over 3 states, listing the top 3 states
    locationAggDescription +=
      ' mainly in ' +
      locationAggArray
        .slice(0, 3)
        .map(([state, count]) => `${state.toUpperCase()} (${count})`)
        .join(', ')
  }

  return (
    <Box>
      <Box sx={{ flexGrow: 1 }} pt={'1rem'}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ px: '1em' }}>
              <Typography variant={'overline'}>Account</Typography>
              <Typography variant={'h4'}>{account.doing_business_as_name}</Typography>
            </Box>
            <List>
              {account.domainz.map(({ domain }) => (
                <ListItem>
                  <Link href={domain.long_name} target={'_blank'}>
                    {domain.short_name}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={6}>
            <Box>{locationAggDescription}</Box>
            <Box>
              {account.nucc_taxonomyz
                .slice(0, 3)
                .map(({ nucc_taxonomy: { long_name } }) => long_name)
                .join(', ')}
            </Box>
            <Box>
              {account.independencez
                .map(({ independence: { long_name } }) => long_name)
                .join(', ')}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <AccountDetailsTabs account={account} />
    </Box>
  )
}
