import { Dictionary, merge } from 'lodash'
import { produce } from 'immer'
import {
  EntityTableDispatchAction,
  EntityTableFilterState,
  EntityTableIntegerFilterState,
  GqlWhereExpression,
} from './types'

export type State = Dictionary<EntityTableFilterState>

export function reducer(state: State, action: EntityTableDispatchAction) {
  if (!state) return state
  // Keep for debugging
  // console.log('REDUCER', action, state)
  switch (action.type) {
    case 'ENABLE_TOGGLED':
      return produce(state, (draft) => {
        draft[action.filterId].active = !draft[action.filterId].active
      })
    case 'OPTION_TOGGLED':
      const optionId = action.optionId
      return produce(state, (draft) => {
        const field = draft[action.filterId]
        if (field.field_type !== 'CATEGORICAL')
          throw new Error('Operation not supported')
        const selectedOptionMap = field.categorical_field.selected_options_map
        if (optionId in selectedOptionMap) {
          delete selectedOptionMap[optionId]
          if (Object.keys(selectedOptionMap).length === 0) {
            draft[action.filterId].active = false
          }
        } else {
          selectedOptionMap[optionId] = true
          draft[action.filterId].active = true
        }
      })
    case 'ALL_OPTIONS_DESELECTED':
      return produce(state, (draft) => {
        const field = draft[action.filterId]
        if (field.field_type !== 'CATEGORICAL')
          throw new Error('Operation not supported')
        field.categorical_field.selected_options_map = {}
        field.active = false
      })
    case 'INTEGER_FILTER_VALUE_CHANGED':
      const field = state[action.filterId] as EntityTableIntegerFilterState
      const integerField = field.integer_field
      if (
        action.value._gte === integerField._gte &&
        action.value._lte === integerField._lte
      ) {
        return state
      }
      return produce(state, (draft) => {
        const field = draft[action.filterId]
        if (field.field_type !== 'INTEGER') throw new Error('Operation not supported')
        field.active =
          typeof action.value._gte === 'number' || typeof action.value._lte === 'number'
        field.integer_field = action.value
      })
    case 'SEARCH_FILTER_TEXT_CHANGED':
      return produce(state, (draft) => {
        const field = draft[action.filterId]
        if (field.field_type !== 'SEARCH') throw new Error('Operation not supported')
        field.active = !!action.text
        field.search_field.text = action.text
      })
    case 'BOOLEAN_FILTER_VALUE_CHANGED':
      return produce(state, (draft) => {
        const field = draft[action.filterId]
        if (field.field_type !== 'BOOLEAN') throw new Error('Operation not supported')
        field.active = field.unchecked_value !== action.value
        field.boolean_field = action.value
      })
    case 'RESET_FILTERS':
      return action.value
    default:
      throw new Error()
  }
}

export function mapFieldTypeToWhere(filterState: EntityTableFilterState) {
  if ('categorical_field' in filterState) {
    return {
      [filterState.categorical_field.categorical_field_type]: {
        _or: Object.keys(filterState.categorical_field.selected_options_map).map(
          (optionId) => ({
            categorical_field_option_id: {
              _eq: optionId,
            },
          })
        ),
      },
    }
    // TODO: Also support this with an filter setting
    //  This is an AND QUERY - currently not supported, but can add a _mode_ to the filter UI
    // return {
    //   [filterState.categorical_field.categorical_field_type]: {
    //     categorical_field_option_id: {
    //       _in: Object.keys(filterState.categorical_field.selected_options_map),
    //     },
    //   },
    // }
  }
  if ('integer_field' in filterState) {
    return {
      ...filterState.integer_field,
    }
  }

  if ('boolean_field' in filterState) {
    if (filterState.boolean_field !== 'any') {
      return {
        _eq: filterState.boolean_field === 'true',
      }
    }
  }
}

export function makeWhereQuery(
  filterState: Dictionary<EntityTableFilterState>,
  preFilter: GqlWhereExpression
) {
  return merge(
    Object.fromEntries(
      Object.values(filterState)
        .filter((state) => state.active)
        .map((state) => [state.field_id, mapFieldTypeToWhere(state)])
    ),
    preFilter
  )
}

export function makeSearchArgs(filterState: Dictionary<EntityTableFilterState>) {
  const searchFilter = filterState['search']
  const similarSearchFilter = filterState['similar_search']
  if (searchFilter.field_type !== 'SEARCH') throw new Error()
  if (typeof similarSearchFilter !== "undefined") {
    if (similarSearchFilter.field_type !== 'SEARCH') throw new Error()
  }
  return {
    search: searchFilter.search_field.text,
    similarSearch: (typeof similarSearchFilter !== "undefined") ? similarSearchFilter.search_field.text : ""
  }
}
