import React from 'react'
import { CategoricalFieldTypeOption } from '../../EntityTable/types'
import { Checkbox, ListItemButton, ListItemText } from '@mui/material'

interface EntityTableFilterItemProps {
  option: CategoricalFieldTypeOption
  onOptionClick: (optionId: string) => void
  selected: boolean
}

const FilterOption = (props: EntityTableFilterItemProps) => {
  const thisOptionId = props.option.id
  return (
    <ListItemButton
      sx={{ maxWidth: '18rem' }}
      dense
      onClick={() => props.onOptionClick(thisOptionId)}
    >
      <Checkbox
        size={'small'}
        color={'secondary'}
        edge={'start'}
        tabIndex={-1}
        disableRipple
        checked={props.selected}
      />
      <ListItemText
        primary={props.option.long_name}
        primaryTypographyProps={{
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
      />
    </ListItemButton>
  )
}

export default FilterOption
